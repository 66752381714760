import React from "react";
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import "../Styles/contactstyle.css";
const Contact = (props) => {
  return (
    <>
      <section id="contact">
        <h1 class="section-header">Contact Us</h1>
        <Container className="pb-4">
          <Row style={{ justifyContent: "center", alignItems: "center" }}>
            <Col xs={12} sm={6}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m17!1m12!1m3!1d719.8759458294649!2d77.50189872770291!3d28.43100407288464!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m2!1m1!2zMjjCsDI1JzUxLjYiTiA3N8KwMzAnMDguNiJF!5e0!3m2!1sen!2sin!4v1680036478192!5m2!1sen!2sin"
                width="80%"
                height="300px"
                style={{ border: 0, margin: "0 8%" }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </Col>
            <Col xs={12} sm={6}>
              <div class="direct-contact-container">
                <ul class="contact-list">
                  <li class="list-item">
                    <i class="fa fa-map-marker fa-2x">
                      <span class="contact-text place">
                        L-804, Express ParkView -II, CHI V, Near Purvanchal
                        Royal City, Greater Noida - 201310
                      </span>
                    </i>
                  </li>

                  <li class="list-item">
                    <i class="fa fa-phone fa-2x">
                      <span class="contact-text phone">
                      <a href="tel:+919811362697" title="Give me a call">
                          (+91) 9811362697
                        </a> 
                        <br />
                        <a href="tel:+919868450661" title="Give me a call">
                          (+91) 9868450661
                        </a>
                         <br />
                        <a href="tel:+916009358415" title="Give me a call">
                          (+91) 6009358415
                        </a>
                        <br />
                        {/* <a href="tel:+919958752912" title="Give me a call">
                          (+91) 9958752912
                        </a>  */}
                      </span>
                    </i>
                  </li>

                  <li class="list-item">
                    <i class="fa fa-envelope fa-2x">
                      <span class="contact-text gmail">
                        <a href="mailto:#" title="Send me an email">
                          saiintsoft@gmail.com
                        </a>
                      </span>
                    </i>
                  </li>
                </ul>

                <hr />

                <div class="copyright" style={{ color: "#fff" }}>
                  &copy; ALL OF THE RIGHTS RESERVED
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default Contact;
