import React from "react";
import '../Styles/landingstyle.css';
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Button from 'react-bootstrap/Button';

const Landing=()=>{
    return(
        <>
        
        <div>
            <h1 className="landing">Sai International Software Solutions
            <span>-managing enterprises through IT solutions-</span>
            </h1>            
        </div>
        
        <div class="hero">  
        <div class="diagonal-hero-bg">
        </div>
        </div> 
                
        </>
        
    )
}

export default Landing;