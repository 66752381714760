import React from 'react';
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ServiceCard from './ServiceCard';

import heart from '../assets/heart.gif';
import edu from '../assets/edu.gif';
import fin from '../assets/fin.gif';
import hotel from '../assets/hotel.gif';
import car from '../assets/truck.gif';
import law from '../assets/law.gif';

import '../Styles/landingstyle.css';


const Services=()=>{
    return(
    <>
    <div className="wu">
            <Container className="pb-5">
            <Row className="ml-3 pt-4">
                {/* <Col className="p-4" lg={6}>
                <div class="slider">
                    <div class="caption">
                        <img src={car} width="100vw" /> &nbsp;&nbsp;=&nbsp;&nbsp; 
                        <div class="text-box">
                            <div>Trust</div>
                            <div>Quality</div>
                            <div>Brand</div>
                            <div>Innovation</div>
                        </div> 
                    </div>
                </div>
                </Col> */}

                <Col className="d-flex justify-content-center p-5 mt-4">
                <div className="mx-auto" style={{align:'right'}}>
                <p style={{color:'#1F4690', fontSize:'1.25em', fontFamily:'Source Sans Pro'}}>At our company, we provide enterprise management solutions through innovative IT solutions that are tailored to the specific needs of each enterprise. We understand that every business is unique and requires personalized attention to optimize their operations and achieve their goals. Therefore, we offer a comprehensive suite of services that address the challenges faced by different enterprises across various industries.</p>
                <p style={{color:'#1F4690', fontSize:'1.25em', fontFamily:'Source Sans Pro'}}>One of the primary reasons to choose us is our deep industry expertise. We have extensive experience in serving clients across diverse sectors, including healthcare, education, finance, automobile, law, and hotel. Our team of experts has a deep understanding of the industry-specific challenges faced by businesses in each sector and can provide customized solutions to address them effectively. </p>
                <p style={{color:'#1F4690', fontSize:'1.25em', fontFamily:'Source Sans Pro'}}>Our company is committed to providing cutting-edge IT solutions that help businesses achieve their objectives while optimizing costs and enhancing operational efficiency. We employ the latest tools and technologies to develop innovative software solutions that automate complex processes and streamline business operations.</p>
                <p style={{color:'#1F4690', fontSize:'1.25em', fontFamily:'Source Sans Pro'}}>Moreover, we understand that the success of a business depends on effective communication, collaboration, and support. Therefore, we prioritize excellent customer service, timely communication, and proactive support to ensure our clients receive the support they need to succeed.</p>
                <p style={{color:'#1F4690', fontSize:'1.25em', fontFamily:'Source Sans Pro'}}>
                At our company, we understand the importance of innovation and customization to meet the unique needs of each industry. Our enterprise management and software development solutions provide businesses with the tools they need to optimize their operations, improve customer experience, and increase profitability.
                </p>
                <p style={{color:'#1F4690', fontSize:'1.25em', fontFamily:'Source Sans Pro'}}>In summary, our company provides comprehensive enterprise management solutions through IT solutions that are tailored to the unique needs of each business. With our deep industry expertise, innovative solutions, and commitment to excellent customer service, we can help businesses achieve their objectives and drive growth.</p>
                </div>
                </Col>

            </Row>
        </Container>
    </div>
    </>
    )
}

export default Services;