import React from "react";
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";

const Banner=(props)=>{
    return(
        <>
        <div className="d-flex text-center justify-content-center p-4" style={{color:'white', background: 'linear-gradient(to right, #ffe5b4, #f7bb99, #e6928e, #c76f8f, #965794, #784985, #5b3b74, #3e2e63, #3b2044, #2f162a, #1d0e16, #000000)'}}>
        <Container>
            <Row>
                <Col>
                    <h2 align="right" className="mx-5">{props.banner}</h2>
                </Col>
            </Row>
        </Container>
        </div>
        </>
    )
}

export default Banner;