import React from "react";
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";

const Banner=(props)=>{
    return(
        <>
        <div className="d-flex text-center justify-content-center p-4" style={{color:'white', background: 'linear-gradient(90deg, hsla(214, 92%, 47%, 1) 0%, hsla(231, 85%, 24%, 1) 100%)'}}>
        <Container>
            <Row>
                <Col>
                    <h2 align="left" className="mx-5">{props.banner}</h2>
                </Col>
            </Row>
        </Container>
        </div>
        </>
    )
}

export default Banner;