import React from 'react';
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';
import ServiceCard from './ServiceCard';

import heart from '../assets/heart.gif';
import edu from '../assets/edu.gif';
import fin from '../assets/fin.gif';
import hotel from '../assets/hotel.gif';
import car from '../assets/truck.gif';
import law from '../assets/law.gif';


const Services=()=>{
    return(
    <>
    <div style={{background:'#FFE5B4'}} className="ml-0">
        <Container className="pb-5">
            <Row className="pt-4">
                <Col className="d-flex justify-content-center p-5 mt-4 pb-1" style={{background:'#fff',boxShadow: '#fff 0px 4px 12px'}} lg={6}>
                <div className="mx-auto" style={{align:'right'}}>
                <p style={{color:'#1F4690', fontSize:'1.25em', fontFamily:'Source Sans Pro'}}>
                As a company that specializes in enterprise management and software development solutions, we cater to a range of sectors, including healthcare, education, finance, automobile, law, and hotel. Our IT solutions streamline and optimize various aspects of business operations, from patient care and educational administration to financial transactions and legal workflows. Our software development services create custom solutions tailored to meet the unique needs of our clients in each industry.
                </p>
                <p style={{color:'#1F4690', fontSize:'1.25em', fontFamily:'Source Sans Pro'}}>
                For healthcare organizations, we develop software solutions that improve patient outcomes by providing better access to medical records, data analysis, and care coordination. In the education sector, our software development solutions create learning management systems that support personalized learning and academic administration. Our finance software solutions automate accounting, payment processing, and budgeting to streamline financial operations.
                </p>
                <p style={{color:'#1F4690', fontSize:'1.25em', fontFamily:'Source Sans Pro'}}>In the automobile industry, we develop software solutions that optimize supply chain management, inventory tracking, and sales management. For law firms, our software solutions automate document management, legal research, and litigation support. Finally, in the hotel industry, we develop software solutions that manage reservations, billing, and guest experience.    </p>
                <p style={{color:'#1F4690', fontSize:'1.25em', fontFamily:'Source Sans Pro'}}>
                At our company, we understand the importance of innovation and customization to meet the unique needs of each industry. Our enterprise management and software development solutions provide businesses with the tools they need to optimize their operations, improve customer experience, and increase profitability.
                </p>
                </div>
                </Col>
                <Col className='mt-4'>
                <Row className="mt-5">
                    <Col xs={6} md={4}><ServiceCard title="Education" img={edu} /></Col>
                    <Col xs={6} md={4}><ServiceCard title="Finances" img={fin} /></Col>
                    <Col xs={6} md={4}><ServiceCard title="Health Care" img={heart} /></Col>
                    <Col xs={6} md={4}><ServiceCard title="Automobile" img={car} /></Col>
                    <Col xs={6} md={4}><ServiceCard title="Law" img={law} /></Col>
                    <Col xs={6} md={4}><ServiceCard title="Hotels" img={hotel}/></Col>
                </Row>
                </Col>
            </Row>
        </Container>
    </div>
    </>
    )
}

export default Services;