import React from 'react';
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import Button from 'react-bootstrap/Button';
import Card from 'react-bootstrap/Card';

const ServiceCard=(props)=>{
    return(
        <>
            <Card className="m-2 mt-4" style={{ width: '10rem', borderRadius:'20px', boxShadow: 'rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px'}}>
                <Card.Img variant="top" src={props.img} style={{borderRadius:'20px', width:'100%'}}/>
                    <Card.Body style={{background:"#3A5BA0", borderRadius:'20px', height:"3.25em"}}>
                         <center><Card.Title style={{color:"#FFA500", fontSize:'1.2em'}}>{props.title}</Card.Title></center>
                        {/* <Card.Text>
                         Some quick example text to build on the card title and make up the
                         bulk of the card's content.
                        </Card.Text> */}
                        {/* <Button variant="primary">Go somewhere</Button> */}
                    </Card.Body>
            </Card>       
        </>
    )
}

export default ServiceCard;