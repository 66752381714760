import React from "react";
import Landing from "./components/Landing";
import Banner from "./components/Banner";
import Services from "./components/Services";
import ServiceCard from "./components/ServiceCard";
import Container from "react-bootstrap/esm/Container";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import FlipBanner from "./components/FlipBanner";
import WhyUs from "./components/WhyUs";
import SerDummy from "./components/SerDummy";
import Test from "./components/Test";
import Contact from "./components/Contact";
function App() {
  return (
    <>
    <Landing />
    <Banner banner="SECTORS CATERED"/>
    <Services />
    <FlipBanner banner="WHY US?"/>
    <WhyUs />
    {/* <Banner banner="SECTORS CATERED"/> */}
    {/* <SerDummy /> */}
    {/* <Banner banner="SECTORS CATERED"/> */}
    {/* <Test />   */}
    <Contact />
    </>
  );
}

export default App;
